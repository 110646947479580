<template>
  <div class="regionalSettings">营业报表</div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() { },
  methods: {},
  mounted() { },
}
</script>
<style lang="less" scoped>
</style>